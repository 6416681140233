import * as React from 'react';
import colors from '../../theme/colors';
const InstagramUpdatedIcon: React.SFC<{ hovered?: boolean }> = ({
  hovered = false,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none">
    <circle cx={30} cy={30} r={30} fill="#252931" />
    <path
      fill={
        hovered
          ? colors.main.accentColor.SECONDARY
          : colors.main.backgroundColor.SECONDARY
      }
      fillRule="evenodd"
      d="M30.001 17c-3.53 0-3.973.016-5.36.08-1.384.063-2.329.284-3.155.608a6.361 6.361 0 0 0-2.303 1.51 6.437 6.437 0 0 0-1.5 2.32c-.323.834-.542 1.786-.605 3.18C17.016 26.096 17 26.543 17 30.1c0 3.558.016 4.003.078 5.4.064 1.395.283 2.347.605 3.18A6.424 6.424 0 0 0 19.181 41a6.365 6.365 0 0 0 2.302 1.513c.827.324 1.772.544 3.156.608 1.386.064 1.83.08 5.36.08 3.53 0 3.972-.016 5.36-.08 1.383-.064 2.33-.285 3.156-.608A6.364 6.364 0 0 0 40.817 41a6.432 6.432 0 0 0 1.5-2.32c.32-.834.54-1.786.605-3.18.062-1.398.078-1.843.078-5.4 0-3.558-.016-4.005-.078-5.402-.066-1.395-.285-2.347-.604-3.18a6.431 6.431 0 0 0-1.5-2.32 6.348 6.348 0 0 0-2.303-1.511c-.829-.324-1.774-.545-3.158-.609-1.386-.063-1.828-.079-5.36-.079h.004Zm-1.166 2.36h1.166c3.471 0 3.883.013 5.253.076 1.268.058 1.956.272 2.414.451a4.02 4.02 0 0 1 1.494.98c.455.459.737.895.973 1.506.178.462.39 1.155.448 2.432.062 1.381.075 1.796.075 5.292 0 3.496-.013 3.911-.075 5.292-.058 1.277-.27 1.97-.448 2.432a4.056 4.056 0 0 1-.973 1.505 4.015 4.015 0 0 1-1.494.98c-.457.18-1.146.393-2.413.451-1.371.063-1.783.076-5.254.076-3.471 0-3.883-.013-5.253-.076-1.268-.059-1.955-.272-2.414-.451a4.027 4.027 0 0 1-1.495-.98 4.066 4.066 0 0 1-.973-1.506c-.178-.461-.39-1.155-.447-2.432-.063-1.381-.075-1.796-.075-5.294 0-3.498.012-3.911.075-5.292.058-1.277.27-1.97.447-2.432a4.06 4.06 0 0 1 .973-1.507 4.032 4.032 0 0 1 1.495-.98c.458-.18 1.146-.393 2.414-.452 1.2-.055 1.664-.07 4.087-.074v.004Zm6.544 3.747c0-.868.698-1.571 1.56-1.571.861 0 1.56.703 1.56 1.571s-.699 1.572-1.56 1.572c-.862 0-1.56-.704-1.56-1.572ZM30 23.373c-3.686 0-6.676 3.012-6.676 6.728 0 3.715 2.99 6.726 6.676 6.726 3.687 0 6.675-3.011 6.675-6.726 0-3.716-2.988-6.728-6.675-6.728Zm4.335 6.726c0-2.412-1.94-4.367-4.334-4.367-2.393 0-4.333 1.955-4.333 4.367s1.94 4.367 4.333 4.367c2.393 0 4.334-1.955 4.334-4.367Z"
      clipRule="evenodd"
    />
  </svg>
);
export default React.memo(InstagramUpdatedIcon);
