import React, { FC, memo, useMemo } from 'react';
import './style.scss';
import Text from '../Text';
import Button from '../Button';
import Category, { CategoryStyle } from '../Category';
import BlogPostInfo from '../BlogPostInfo';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ServiceTitle from '../ServiceTitle';
import { Tag } from '../../utils/blogTypes';
import { formatTimestamp } from '../../utils/dateHelpers';
import Link from '../Link';
import useScreenMode from '../../hooks/useScreenMode';

export interface PostData {
  title: string;
  date: string;
  time: string;
  tags: Tag[];
  image: IGatsbyImageData;
  slug: string;
}

export interface SanityArticleData {
  nodes: {
    slug: {
      current: string;
    };
    tags: Tag[];
    title: string;
    _updatedAt: string;
    headerImage: {
      mainImage: {
        asset: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  }[];
}

type SelectedArticle = {
  slug: string;
  readTime: string;
};

const tagsColors: Record<Tag, CategoryStyle> = {
  [Tag.CaseStudy]: 'orange',
  [Tag.ProductStrategy]: 'green',
  [Tag.Tech]: 'dark-grey',
  [Tag.Development]: 'dark-grey',
};

const tagsTitles: Record<Tag, string> = {
  [Tag.CaseStudy]: 'Case Study',
  [Tag.ProductStrategy]: 'Product strategy',
  [Tag.Tech]: 'Tech',
  [Tag.Development]: 'Development',
};

interface Props {
  title: string;
  articles: SanityArticleData;
  mainArticleDescription: string;
  selectedArticles: SelectedArticle[];
}

const renderPost = (item: PostData, index: number) => {
  return (
    <li>
      <div className="blog-posts-list__content__list__divider" />

      <Link
        href={`/blog/${item.slug}/`}
        className="blog-posts-list__content__list__item"
      >
        <GatsbyImage
          image={item.image}
          alt={item.title}
          objectFit="cover"
          className="blog-posts-list__content__list__item__image"
        />
        <div className="blog-posts-list__content__list__item__wrapper">
          <div className="blog-posts-list__content__list__item__info">
            <BlogPostInfo variant="date" info={formatTimestamp(item.date)} />
            <BlogPostInfo variant="time" info={`${item.time} min read`} />
          </div>
          <Text
            type="h3"
            color="light"
            weight="bold"
            className="blog-posts-list__content__list__item__title"
          >
            {item.title}
          </Text>
        </div>
      </Link>
    </li>
  );
};

const renderTag = (item: Tag, index: number) => {
  return (
    <Category isWide style={tagsColors[item]} key={index} isCapital>
      {tagsTitles[item]}
    </Category>
  );
};

const BlogPostsList: FC<Props> = ({
  title,
  mainArticleDescription,
  articles,
  selectedArticles,
}) => {
  const filteredArticles = useMemo<PostData[]>(() => {
    const selectedArticlesSlugs = selectedArticles.map((item) => item.slug);

    return articles.nodes
      .filter((item) => selectedArticlesSlugs.includes(item.slug.current))
      .sort(
        (a, b) =>
          selectedArticlesSlugs.indexOf(a.slug.current) -
          selectedArticlesSlugs.indexOf(b.slug.current)
      )
      .map((item, index) => ({
        title: item.title,
        date: item._updatedAt,
        time: selectedArticles[index].readTime,
        image: item.headerImage.mainImage.asset.gatsbyImageData,
        tags: item.tags,
        slug: item.slug.current,
      }));
  }, [articles.nodes, selectedArticles]);

  const screenMode = useScreenMode();
  const showFullInfo = screenMode === 'desktop' || screenMode === 'tablet';

  return (
    <section className="blog-posts-list">
      <div className="blog-posts-list__container">
        <ServiceTitle color="light">{title}</ServiceTitle>

        <div className="blog-posts-list__divider"></div>
        <Button
          size="regular"
          href={'/blog/'}
          rounded
          color="dark-grey"
          className="blog-posts-list__button"
        >
          {'All blog posts'}
        </Button>
        <div className="blog-posts-list__content">
          <Link
            href={`/blog/${filteredArticles[0].slug}/`}
            className="blog-posts-list__content__main-item"
          >
            <GatsbyImage
              image={filteredArticles[0].image}
              alt={filteredArticles[0].title}
              objectFit="cover"
              className="blog-posts-list__content__main-item__image"
            />
            <div className="blog-posts-list__content__main-item__info">
              <BlogPostInfo
                variant="date"
                info={formatTimestamp(filteredArticles[0].date)}
              />
              <BlogPostInfo
                variant="time"
                info={`${filteredArticles[0].time} min read`}
              />
            </div>
            <Text
              type="h3"
              weight="bold"
              color="light"
              className="blog-posts-list__content__main-item__title"
            >
              {filteredArticles[0].title}
            </Text>
            {showFullInfo && (
              <>
                <Text
                  type="p"
                  color="dark-grey"
                  className="blog-posts-list__content__main-item__description p-updated"
                >
                  {mainArticleDescription}
                </Text>
                <div className="blog-posts-list__content__main-item__tags">
                  {filteredArticles[0].tags.map(renderTag)}
                </div>
              </>
            )}
          </Link>
          <ul className="blog-posts-list__content__list">
            {filteredArticles.slice(1).map(renderPost)}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default memo(BlogPostsList);
