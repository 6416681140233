import React, { FC, memo } from 'react';

import './style.scss';
import ServiceTitle from '../ServiceTitle';
import ServiceAboutStats from './ServiceAboutStats';
import ServiceAboutList from './ServiceAboutList';
import {
  Service,
  ServiceAboutContentVariants,
  ServiceAboutItem,
} from '../../utils/serviceTypes';
import Markdown from '../Markdown';
interface Props {
  about: Service['about'];
}

const parseContentItem = (item: ServiceAboutItem) => {
  switch (item.type) {
    case ServiceAboutContentVariants.List:
      return <ServiceAboutList list={item.list} />;
    case ServiceAboutContentVariants.Stats:
      return <ServiceAboutStats stats={item.stats} />;
    case ServiceAboutContentVariants.Text:
      return <Markdown source={item.text} className="service__about__text" />;
    default:
      return <div></div>;
  }
};

const ServiceAbout: FC<Props> = ({ about }) => {
  return (
    <section className="service__about service__container">
      <ServiceTitle>{about.title}</ServiceTitle>
      <div className="service__about__content">
        {about.items.map(parseContentItem)}
      </div>
    </section>
  );
};

export default memo(ServiceAbout);
