import React, { FC, memo } from 'react';
import Text from '../Text';
import StatIcon from '../icons/StatIcon';
import { AboutStat } from '../../utils/serviceTypes';

interface Props {
  stats: AboutStat[];
}

const renderStats = (item: AboutStat, index: number) => (
  <li className="service__about__stats__item">
    <Text type="p" key={index} className="service__about__stats__item__title">
      {item.value}
    </Text>
    <StatIcon className="service__about__stats__item__icon" />

    <Text
      type="p"
      key={index}
      className="service__about__stats__item__description p-updated"
    >
      {item.description}
    </Text>
  </li>
);

const ServiceAboutStats: FC<Props> = ({ stats }) => {
  return <ul className="service__about__stats">{stats.map(renderStats)}</ul>;
};

export default memo(ServiceAboutStats);
