import * as React from 'react';
const StatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={11}
    fill="none"
    {...props}
  >
    <path fill="#F8B91C" d="m7.083.5 7.084 10H0L7.083.5Z" />
  </svg>
);
export default React.memo(StatIcon);
