import React, { FC, memo } from 'react';

import './style.scss';
import { Service, ServiceCompany } from '../../utils/serviceTypes';

const renderCompaniesIcons = (item: ServiceCompany, index: number) => (
  <li
    dangerouslySetInnerHTML={{ __html: item.icon }}
    className="service__companies__list__item"
  ></li>
);

interface Props {
  icons: Service['companies'];
}

const ServiceCompanies: FC<Props> = ({ icons }) => {
  return (
    <section className="service__companies service__container">
      <ul className="service__companies__list">
        {icons.map(renderCompaniesIcons)}
      </ul>
    </section>
  );
};

export default memo(ServiceCompanies);
