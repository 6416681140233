import React, { useEffect, useState } from 'react';
import * as constants from '../theme/constants';

const useScreenMode = () => {
  const [screenMode, setScreenMode] = useState<
    'mobile' | 'tablet' | 'tablet-portrait' | 'desktop' | undefined
  >(undefined);

  const onResize = React.useCallback(() => {
    const screenWidth = window.innerWidth;
    setScreenMode(
      screenWidth > constants.breakTablet
        ? 'desktop'
        : screenWidth > constants.breakTabletPortrait
          ? 'tablet'
          : screenWidth > constants.breakMobile
            ? 'tablet-portrait'
            : 'mobile'
    );
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize]);

  return screenMode;
};

export default useScreenMode;
