import * as React from 'react';
import colors from '../../theme/colors';
const FacebookUpdatedIcon: React.SFC<{ hovered?: boolean }> = ({
  hovered = false,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none">
    <circle cx={30} cy={30} r={30} fill="#252931" />
    <path
      fill={
        hovered
          ? colors.main.accentColor.SECONDARY
          : colors.main.backgroundColor.SECONDARY
      }
      fillRule="evenodd"
      d="m37 25.174-.547 4.825h-4.139V44H26.1V30H23v-4.825h3.099V22.27c0-3.925 1.75-6.27 6.726-6.27h4.138v4.825h-2.588c-1.932 0-2.06.68-2.06 1.937v2.413L37 25.174Z"
      clipRule="evenodd"
    />
  </svg>
);
export default React.memo(FacebookUpdatedIcon);
