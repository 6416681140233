import React, { memo, useCallback } from 'react';
import './style.scss';
import ContactForm from '../ContactForm';
import Communication from '../Communication';

import FacebookUpdatedIcon from '../icons/FacebookUpdatedIcon';
import LinkedInUpdatedIcon from '../icons/LinkedInUpdatedIcon';
import InstagramUpdatedIcon from '../icons/InstagramUpdatedIcon';
import { AddressVariants } from '../../models/Address';

const MessageUs = () => {
  const setDataLayerEvent = useCallback(
    () => window?.dataLayer?.push?.({ event: 'contact_us_form_success' }),
    []
  );

  return (
    <section className="message-us" id="message-us">
      <div className="message-us__container">
        <ContactForm isLegacy={false} onSuccess={setDataLayerEvent} />

        <Communication
          networking={[
            {
              icon: FacebookUpdatedIcon,
              href: 'https://www.facebook.com/stormotion/',
            },
            {
              icon: InstagramUpdatedIcon,
              href: 'https://www.instagram.com/stormotion_team/',
            },
            {
              icon: LinkedInUpdatedIcon,
              href: 'https://www.linkedin.com/company/stormotion/',
            },
          ]}
          addresses={[
            {
              title: 'Contact E-Mail',
              address: 'hello@stormotion.io',
              link: 'hello@stormotion.io',
              variant: AddressVariants.Email,
            },
            {
              title: 'Stormotion Legal Office',
              address: 'Kauptmehe tn. 7-120',
              cityZip: '10114 Tallinn, Estonia',
              variant: AddressVariants.Location,
            },
            {
              title: 'Stormotion Development Office',
              address: 'PE Oleksii Bulavka\nZaporizke Hwy. 48/120',
              cityZip: '49040 Dnipro, Ukraine',
              variant: AddressVariants.Location,
            },
          ]}
          isLegacy={false}
        />
      </div>
    </section>
  );
};

export default memo(MessageUs);
