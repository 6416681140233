import * as React from 'react';
import colors from '../../theme/colors';
const LinkedInUpdatedIcon: React.SFC<{ hovered?: boolean }> = ({
  hovered = false,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none">
    <circle cx={30} cy={30} r={30} fill="#252931" />
    <path
      fill={
        hovered
          ? colors.main.accentColor.SECONDARY
          : colors.main.backgroundColor.SECONDARY
      }
      fillRule="evenodd"
      d="M22.65 41V24.133h-5.334v16.869h5.334ZM19.982 21.83c1.86 0 3.018-1.296 3.018-2.914C22.965 17.259 21.842 16 20.017 16 18.193 16 17 17.26 17 18.915c0 1.618 1.157 2.914 2.948 2.914h.034ZM26 41h5.57v-9.275c0-.497.035-.993.182-1.348.403-.991 1.32-2.019 2.858-2.019 2.015 0 2.821 1.523 2.821 3.756v8.887H43v-9.525C43 26.375 40.252 24 36.588 24c-3.004 0-4.323 1.665-5.056 2.8h.037v-2.41H26C26.073 25.95 26 41 26 41Z"
      clipRule="evenodd"
    />
  </svg>
);
export default React.memo(LinkedInUpdatedIcon);
