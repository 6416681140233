import React, { FC, memo } from 'react';
import './style.scss';

import CalendarDoneIcon from '../icons/CalendarDoneIcon';
import TimeIcon from '../icons/TimeIcon';
import Text from '../Text';

type Variant = 'time' | 'date';

interface Props {
  variant: Variant;
  info: string | number;
}

const Icons: Record<Variant, JSX.Element> = {
  time: <TimeIcon color="grey" />,
  date: <CalendarDoneIcon color="grey" />,
};

const BlogPostInfo: FC<Props> = ({ variant, info }) => {
  return (
    <div className="blog-post-info">
      {Icons[variant]}
      <Text type="p" color="dark-grey">
        {info}
      </Text>
    </div>
  );
};

export default memo(BlogPostInfo);
