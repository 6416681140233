import React, { FC, memo, useCallback, useRef } from 'react';
import './style.scss';
import Text from '../Text';
import Button, { ButtonLinkType } from '../Button';
import SliderHeader from '../SliderHeader';
import SliderControlButton from '../SliderControlButton';
import { Service } from '../../utils/serviceTypes';

interface SliderData {
  title: string;
  description: string;
}

const renderSLides = (item: SliderData, index: number) => (
  <li key={index} className="work-process__slider__slide">
    <Text
      type="h3"
      weight="medium"
      className="work-process__slider__slide__title"
    >
      {item.title}
    </Text>
    <p className="work-process__slider__slide__description p-updated">
      {item.description}
    </p>
  </li>
);

const SCROLL_STEP_WIDTH = 400;

interface Props {
  workflow: Service['workflow'];
}

const WorkProcess: FC<Props> = ({ workflow }) => {
  const sliderRef = useRef<HTMLUListElement>(null);

  const onScrollNext = useCallback(() => {
    const currentPosition = sliderRef.current?.scrollLeft ?? 0;

    sliderRef.current?.scrollTo({
      left: currentPosition + SCROLL_STEP_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  const onScrollPrev = useCallback(() => {
    const currentPosition = sliderRef.current?.scrollLeft ?? 0;

    sliderRef.current?.scrollTo({
      left: currentPosition - SCROLL_STEP_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className="work-process">
      <div className="work-process__header">
        <SliderHeader
          title={workflow.title}
          subtitle="Shuffle through the slider. Drag or Swipe."
          isSubtitleOnlyOnMobile
        />
      </div>
      <ul className="work-process__slider" ref={sliderRef}>
        {workflow.items.map(renderSLides)}
      </ul>
      <div className="work-process__footer">
        <div>
          <Text type="p" className="work-process__description p-updated">
            {workflow.description}
          </Text>
          <Button
            size="regular"
            rounded
            outerLink
            href={workflow.buttonHref}
            color="secondary"
            className="work-process__button"
            linkType={ButtonLinkType.ANCHOR}
          >
            {workflow.buttonTitle}
          </Button>
        </div>
        <div className="work-process__slider__controls">
          <SliderControlButton onClick={onScrollPrev} direction="prev" />
          <SliderControlButton onClick={onScrollNext} direction="next" />
        </div>
      </div>
    </section>
  );
};

export default memo(WorkProcess);
