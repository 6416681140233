import React, { FC, memo } from 'react';
import Markdown from '../Markdown';

const renderStats = (item: string, index: number) => (
  <li className="service__about__list__item">
    <Markdown source={item} />
  </li>
);

interface Props {
  list: string[];
}

const ServiceAboutList: FC<Props> = ({ list }) => {
  return <ul className="service__about__list">{list.map(renderStats)}</ul>;
};

export default memo(ServiceAboutList);
