import { HeadProps, PageProps, graphql } from 'gatsby';
import React, { useMemo } from 'react';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import Questions from '../components/Questions';
import SEO from '../components/seo';
import './style.scss';
import WorkProcess from '../components/WorkProcess';
import BlogPostsList from '../components/BlogPostsList';
import ServiceAbout from '../components/ServiceAbout';
import ServiceCompanies from '../components/ServiceCompanies';
import ServiceOffer from '../components/ServiceOffer';
import MessageUs from '../components/MessageUs';
import Footer from '../components/Footer';
import {
  Service,
  ServiceAppsPreview,
  ServiceArticlesPreview,
} from '../utils/serviceTypes';
import TabItemUpdated from '../components/LinkOutreach/TabItemUpdated';
import LatestProjects, { SliderData } from '../components/LatestProjects';

import HeaderHeroUpdated from '../components/HeaderHero/HeaderHeroUpdated';
import SpecialUpdated from '../components/Special/SpecialUpdated';
import LinkOutreachUpdated from '../components/LinkOutreach/LinkOutreachUpdated';
import TehnologyUpdated from '../components/Tehnology/TehnologyUpdated';
import CommentsUpdated from '../components/Comments/CommentsUpdated';

type DataProps = {
  sanityService: Service;
  allSanityArticle: ServiceArticlesPreview;
  allSanityApp: ServiceAppsPreview;
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
};

function ProjectTemplate({ data }: PageProps<DataProps>) {
  const { sanityService, allSanityArticle, allSanityApp } = data;

  const {
    header,
    companies,
    about,
    offer,
    tabs,
    workflow,
    technologies,
    faq,
    special,
    blog,
    projects,
    feedback,
  } = sanityService;

  const dataConvertForTab = useMemo(
    () =>
      tabs?.items?.map((t) => ({
        ...t,
        description: (
          <TabItemUpdated
            data={{ ...t, list: t.items, image: t.image.asset.gatsbyImageData }}
          />
        ),
      })),
    [tabs?.items]
  );

  const convertedApps = useMemo<SliderData[]>(
    () =>
      allSanityApp.nodes
        .filter((item) => projects.items.includes(item.title))
        .map((item) => ({
          title: item.title,
          subtitle: item.moto,
          description: item.serviceData.description,
          tags: item.tags,
          image: item.serviceData.image.asset.gatsbyImageData,
          slug: item.slug.current,
        }))
        .sort(
          (a, b) =>
            projects.items.indexOf(a.title) - projects.items.indexOf(b.title)
        ),
    [allSanityApp.nodes, projects.items]
  );

  return (
    <Layout>
      <Navigation headerBackgroundColor="none" />
      <article className="project">
        <HeaderHeroUpdated
          list={header.items}
          btnTitle={header.buttonTitle}
          btnHref={header.buttonHref}
          title={header.title}
          bgImage={header.bgImage.asset.gatsbyImageData}
          description={header.description}
        />

        <ServiceCompanies icons={companies} />

        <ServiceAbout about={about} />

        <ServiceOffer offer={offer} />

        <SpecialUpdated traits={special.items} title={special.title} />

        <LinkOutreachUpdated
          backgroundMode="darkBG"
          variant="primary"
          colorMode={'light'}
          tabs={dataConvertForTab}
          header={tabs.title}
          showScrollButton={true}
        />

        <WorkProcess workflow={workflow} />

        <LatestProjects title={projects.title} projects={convertedApps} />

        <TehnologyUpdated
          tabs={technologies.tabs}
          header={technologies.title}
          details={technologies.details}
        />

        <CommentsUpdated
          title={feedback.title}
          commentsOrder={feedback.items}
        />

        <BlogPostsList
          selectedArticles={blog.articles}
          articles={allSanityArticle}
          title={blog.title}
          mainArticleDescription={blog.mainArticleDescription}
        />

        <Questions
          questions={faq.faq ?? []}
          title={faq.title}
          isLegacy={false}
        />
        <MessageUs />
      </article>

      <Footer noFooterCard={true} isLegacy={false} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    sanityService(id: { eq: $id }) {
      title
      description

      header {
        title
        items
        description
        buttonTitle
        buttonHref
        bgImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      companies {
        icon
      }

      about {
        title
        items {
          type
          text
          list
          stats {
            value
            description
          }
        }
      }

      offer {
        title
        items {
          title
          description
          items
        }
      }

      special {
        title
        items {
          icon
          title
          description
        }
      }

      tabs {
        title
        items {
          header
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          imageSign
          title
          description
          items {
            icon
            text
          }
          btnText
          btnHref
        }
      }

      workflow {
        title
        items {
          title
          description
        }
        description
        buttonTitle
        buttonHref
      }

      projects {
        title
        items
      }

      technologies {
        title
        tabs {
          title
          code
        }
        details {
          title
          items
        }
      }

      feedback {
        title
        items
      }

      blog {
        articles {
          readTime
          slug
        }
        mainArticleDescription
        title
      }

      faq {
        title
        faq {
          question
          answer
        }
      }
    }

    allSanityArticle {
      nodes {
        slug {
          current
        }
        tags
        title
        _updatedAt
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    allSanityApp {
      nodes {
        title
        tags
        moto
        slug {
          current
        }
        serviceData {
          description
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }: HeadProps<DataProps>) => {
  const { sanityService } = data;

  const { title, description, faq } = sanityService;

  return <SEO title={title} description={description} questions={faq.faq} />;
};

export default ProjectTemplate;
