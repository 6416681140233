import React from 'react';
import GatsbySvgImage from '../GatsbySvgImage';
import Link from '../Link';
import Text from '../Text';

type TList = {
  icon: any;
  text: string;
};

interface ITabItem {
  data: {
    image: any;
    imageSign?: string;
    title: string;
    description: string;
    btnText?: string;
    list: TList[];
    btnHref: string;
  };
}
const TabItem: React.FC<ITabItem> = ({ data }) => {
  const classPrefix = 'link-outreach';

  return (
    <div className={`${classPrefix}__tab__container`}>
      <div className={`${classPrefix}__tab__col`}>
        <div className={`${classPrefix}__tab__image__container`}>
          <GatsbySvgImage image={data.image} alt={data.title} />
        </div>
        <Text color="light" type="p">
          {data.imageSign ?? ''}
        </Text>
      </div>
      <div className={`${classPrefix}__tab__col`}>
        <Text color="light" type="h3" className="h2" weight="bold">
          {data.title}
        </Text>
        <Text color="light" type="p" className="p-updated">
          {data.description}
        </Text>
        <div className={`${classPrefix}__tab__list`}>
          {data.list.map((l, i) => {
            return (
              <div className={`${classPrefix}__tab__list__item`} key={i}>
                <div dangerouslySetInnerHTML={{ __html: l.icon }} />
                <Text color="light" type="p" className="p-updated">
                  {l.text}
                </Text>
              </div>
            );
          })}
        </div>
        <div>
          <Link href={data.btnHref} className="p-updated">
            {data.btnText ?? ''}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TabItem;
