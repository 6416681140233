import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Tag } from './blogTypes';
import { AppNames } from './appTypes';

export interface APIServiceImageData {
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
}

export enum ServiceAboutContentVariants {
  List = 'list',
  Stats = 'stats',
  Text = 'text',
}

export interface AboutStat {
  value: string;
  description: string;
}

interface ServiceAboutTextItem {
  type: ServiceAboutContentVariants.Text;
  text: string;
}
interface ServiceAboutStatsItem {
  type: ServiceAboutContentVariants.Stats;
  stats: AboutStat[];
}
interface ServiceAboutListItem {
  type: ServiceAboutContentVariants.List;
  list: string[];
}

export type ServiceAboutItem =
  | ServiceAboutTextItem
  | ServiceAboutListItem
  | ServiceAboutStatsItem;

interface ServiceAbout {
  title: string;
  items: ServiceAboutItem[];
}

interface ServiceHeader {
  title: string;
  items: string[];
  description: string;
  buttonTitle: string;
  buttonHref: string;
  bgImage: APIServiceImageData;
}
export interface ServiceCompany {
  icon: string;
}

interface ServiceFAQ {
  title: string;
  faq: {
    question: string;
    answer: string;
  }[];
}

export interface ServiceOffer {
  title: string;
  items: {
    title: string;
    description: string;
    items: string[];
  }[];
}

export interface ServiceWorkflow {
  title: string;
  items: {
    title: string;
    description: string;
  }[];
  description: string;
  buttonTitle: string;
  buttonHref: string;
}

export interface ServiceTechnologies {
  title: string;
  tabs: {
    title: string;
    code: string;
  }[];
  details: {
    title: string;
    items: string;
  }[];
}
export interface ServiceSpecial {
  title: string;
  items: {
    icon: string;
    title: string;
    description: string;
  }[];
}

export interface ServiceTabs {
  title: string;
  items: {
    header: string;
    image: APIServiceImageData;
    imageSign: string;
    title: string;
    description: string;
    items: {
      icon: string;
      text: string;
    }[];
    btnText: string;
    btnHref: string;
  }[];
}

export interface ServiceBlog {
  articles: {
    readTime: string;
    slug: string;
  }[];
  mainArticleDescription: string;
  title: string;
}

export interface ServiceProjects {
  title: string;
  items: AppNames[];
}

export interface ServiceFeedback {
  title: string;
  items: string[];
}

export interface Service {
  title: string;
  description: string;
  header: ServiceHeader;
  blog: ServiceBlog;
  faq: ServiceFAQ;
  companies: ServiceCompany[];
  about: ServiceAbout;
  offer: ServiceOffer;
  workflow: ServiceWorkflow;
  technologies: ServiceTechnologies;
  special: ServiceSpecial;
  tabs: ServiceTabs;
  projects: ServiceProjects;
  feedback: ServiceFeedback;
}

export interface ServiceArticlesPreview {
  nodes: {
    slug: {
      current: string;
    };
    tags: Tag[];
    title: string;
    _updatedAt: string;
    headerImage: {
      mainImage: APIServiceImageData;
    };
  }[];
}

export interface ServiceAppsPreview {
  nodes: {
    title: AppNames;
    tags: string[];
    moto: string;
    slug: {
      current: string;
    };
    serviceData: {
      description: string;
      image: APIServiceImageData;
    };
  }[];
}
