import React, { FC, memo } from 'react';
import Text from '../Text';
import Check from '../icons/Check';
import colors from '../../theme/colors';

import './style.scss';
import ServiceTitle from '../ServiceTitle';
import { Service } from '../../utils/serviceTypes';

interface Props {
  offer: Service['offer'];
}

const ServiceOffer: FC<Props> = ({ offer }) => {
  return (
    <section className="service__offer">
      <div className="service__offer__container">
        <ServiceTitle color="light" className="service__offer__title">
          {offer.title}
        </ServiceTitle>
        <ul className="service__offer__sections">
          {offer.items.map((item, index) => (
            <li className="service__offer__sections__item" key={index}>
              <Text
                type="h3"
                weight="medium"
                className="service__offer__sections__item__title h4"
                color="light"
              >
                {item.title}
              </Text>
              <Text
                type="p"
                className="service__offer__sections__item__description p-updated"
                color="light"
              >
                {item.description}
              </Text>
              <ul className="service__offer__sections__item__list">
                {item.items.map((l, i) => (
                  <li key={i}>
                    <div>
                      <Check color={colors.main.accentColor.SECONDARY} />
                    </div>
                    <Text type="p" color="light" className="p-updated">
                      {l}
                    </Text>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default memo(ServiceOffer);
